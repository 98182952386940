@use "./variables" as *;
@use "./typefaces" as *;

/* Responsive Design */

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

/* icon */

@mixin icon {
  height: 2rem;
  width: 2rem;
  fill: $primary-dark;

  &:hover {
    fill: darken($primary-dark, 18%);
  }
}

@mixin sm-icon {
  @include icon;
  height: 1.25rem;
  width: 1.25rem;
}

/* flex */

@mixin flexbox($direction: row, $justify: center, $align-items: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align-items;
}

@mixin side-padding {
  padding-left: $spacing-s;
  padding-right: $spacing-s;

  @include tablet {
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
  }

  @include desktop {
    padding-left: $spacing-xxl;
    padding-right: $spacing-xxl;
  }
}

@mixin vertical-padding {
  padding-top: $spacing-s;
  padding-bottom: $spacing-s;

  @include tablet {
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
  }
}

@mixin uppercase-header {
  font-size: 1rem; //change to match typography once have
  font-weight: bold;
  text-transform: uppercase;
}
