@use "./styles/mixins.scss" as *;
@use "./styles/variables.scss" as *;

.app {
  width: 100%;
  min-height: 100vh;
  background-color: $background-light;
  @include flexbox($direction: column, $align-items: flex-start);

  @include tablet {
    padding-top: 100px;
    align-items: center;
  }

  &__text {
    margin-top: calc($nav-height + $spacing-m);
    margin-left: $spacing-s;
    max-width: 400px;
  }
}
