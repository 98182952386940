@font-face {
  font-family: "Helvetica Neue";
  src: url("../../src/assets/fonts/HelveticaNeue.woff2") format("woff2"),
    url("../../src/assets/fonts/HelveticaNeue.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../../src/assets/fonts/HelveticaNeueBold.woff2") format("woff2"),
    url("../../src/assets/fonts/HelveticaNeueBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
