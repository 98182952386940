@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.project {
  padding: 1rem 1rem 1rem 0;
  border-radius: 5px;

  &__title {
    @include uppercase-header;
  }

  &__readmore {
    font-size: 0.75rem;

    &:hover {
      text-decoration: underline;
    }
  }

  &__header {
    @include flexbox($justify: space-between);
  }

  &__icons {
    @include flexbox;
    gap: 0.5rem;
  }
}
