@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.nav {
  width: 100%;
  @include side-padding;
  @include vertical-padding;
  @include flexbox($justify: space-between, $align-items: flex-start);
  position: fixed;
  top: 0;
  background-color: white;
  height: $nav-height;
  z-index: 10;

  &__contact {
    display: flex;
    gap: $spacing-m;
  }

  &__name {
    font-weight: 600;
  }

  // &::after {
  //   position: absolute;
  //   content: "";
  //   z-index: 1;
  //   left: 0;
  //   right: 0;
  //   top: $nav-height;
  //   height: 200px;
  //   pointer-events: none;

  //   background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  // }
}
