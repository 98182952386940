$background-light: #fff;
$primary-dark: #608c57;
$primary-light: #bfe3c0;
$text-light: #fff;

$spacing-xs: 0.25rem;
$spacing-s: 0.5rem;
$spacing-m: 1rem;
$spacing-lg: 1.5rem;
$spacing-xl: 2rem;
$spacing-xxl: 4rem;

$border-radius: 0.25rem;

$field-input-height: 2rem;
$transition: 0.4s;
$nav-height: 70px;

$icon-spacing-unit: 0.5rem;
