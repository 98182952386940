@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;


.cta {
    align-self: flex-end;
    height: $field-input-height;
    width: 100%;
    transition: $transition;
    border: none;
    background-color: $primary-dark;
    color: $text-light;
    border: 1px solid $primary-dark;

    @include tablet {
        max-width: 8rem;
    }

    &:hover {
        background-color: $background-light;
        color: $primary-dark;
    }

    &__contents {
        @include flexbox;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &__icon {
        position: absolute;
        left: $icon-spacing-unit;
    }




    // &--reverse {
    //     border: 1px solid $secondary-background;
    //     background-color: $light-background;
    //     color: $dark-text;

    //     &:hover:enabled {
    //         background-color: $secondary-background;
    //         color: $light-text;
    //     }
    // }

}