@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;
@use "../../styles/variables.scss" as *;

@keyframes grow {
  0% {
    min-height: 0;
    max-height: 0;
    opacity: 0;
  }
  99% {
    min-height: 700px;
    max-height: 700px;
  }
  100% {
    opacity: 1;
    max-height: auto;
  }
}

@keyframes shrink {
  0% {
    max-height: auto;
    opacity: 1;
  }
  1% {
    max-height: 700px;
    min-height: 700px;
  }
  100% {
    min-height: 0;
    max-height: 0;
    opacity: 0;
  }
}

.section {
  @include desktop {
    margin-bottom: $spacing-xl;
  }

  &__title {
    &:hover {
      cursor: pointer;
      color: darken($primary-dark, 20%);

      .section__arrow {
        fill: darken($primary-dark, 20%);
      }
    }
  }

  &__contents {
    margin-bottom: $spacing-xxl;
  }

  &__content-container {
    overflow: hidden;
    min-height: 0px;

    &--open {
      animation: grow 0.7s ease-in-out;
    }

    &--closed {
      animation: shrink 0.7s ease-in-out;
      height: 0px;
    }
  }
  &__arrow {
    @include sm-icon;
    margin-left: 1rem;
    transition: 0.3s;

    &--rotated {
      transform: rotate(-90deg);
    }
  }
}
