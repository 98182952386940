@use "../../styles/mixins.scss" as *;
@use '../../styles/variables.scss' as *;

.github-icon {
  @include icon;

  &--small {
    @include sm-icon;
  }

  &--lighten {
    fill: lighten($primary-dark, 45%);
  }

}