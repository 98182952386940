@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.img-container {
  display: none;
  background-image: url("../../assets/imgs/houseplant.jpg");
  background-size: cover;
  position: fixed;
  height: 7rem;
  width: 7rem;
  right: $spacing-s;
  bottom: 0;

  &--show {
    display: inline;
  }

  @include tablet {
    display: inline;
    // height: 7rem;
    // width: 7rem;
    left: $spacing-xl;
    bottom: 0;
  }

  @include desktop {
    left: $spacing-xxl;
    height: 13rem;
    width: 13rem;
  }
}
