@use "./styles/typefaces" as *;
@use "./styles/variables.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: $primary-dark;
  font-family: "Helvetica Neue", sans-serif;
}

a,
:visited {
  color: inherit;
  text-decoration: none;
}

/* Typography */
/* Do better */
h1 {
  margin-bottom: $spacing-m;
  font-family: "Fira Code", monospace;
  font-weight: 400;
}

h2 {
  font-family: "Fira Code", monospace;
  font-weight: 400;
  margin-bottom: $spacing-m;
}

p {
  line-height: 150%;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 0.375rem;

  &-thumb {
    background-color: $primary-dark;
    border-radius: $border-radius;

    &:hover {
      background-color: darken($primary-dark, 50%);
    }
  }

  &-track {
    background-color: $background-light;
  }
}
